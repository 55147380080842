exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-approach-jsx": () => import("./../../../src/pages/approach.jsx" /* webpackChunkName: "component---src-pages-approach-jsx" */),
  "component---src-pages-california-privacy-disclosures-jsx": () => import("./../../../src/pages/california-privacy-disclosures.jsx" /* webpackChunkName: "component---src-pages-california-privacy-disclosures-jsx" */),
  "component---src-pages-community-jsx": () => import("./../../../src/pages/community.jsx" /* webpackChunkName: "component---src-pages-community-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-esg-jsx": () => import("./../../../src/pages/esg.jsx" /* webpackChunkName: "component---src-pages-esg-jsx" */),
  "component---src-pages-european-regulatory-disclosures-jsx": () => import("./../../../src/pages/european-regulatory-disclosures.jsx" /* webpackChunkName: "component---src-pages-european-regulatory-disclosures-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */)
}

